const TaskOverviewContentBlock = ({ contentList }) => {
  return contentList ? (
    <>
      <div className="d-flex align-items-center mb-3">
        <img
          alt="lesson content"
          src="/img/lesson_content.png"
          className="avatar avatar-sm"
        />
        <div className="font-weight-bold ml-2">CONTENT</div>
      </div>
      <ul style={{ paddingInlineStart: "2em" }}>
        {/* <li>Defining investments, scams and insurance</li>
        <li>
          How to protect yourself against these things and manage money
          effectively
        </li> */}
        {contentList.map((content, index) => {
          return <li key={index}>{content}</li>;
        })}
      </ul>
    </>
  ) : (
    <></>
  );
};

export default TaskOverviewContentBlock;
