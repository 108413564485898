import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const ExploreCourseTaskSwitch = ({ switchMode, isCourse = true }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="light text-primary rounded-pill py-1 text-xl mr-0">
        {isCourse ? "Courses" : "Lessons"}
        <FontAwesomeIcon className="ml-3" icon={faAngleDown} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          className="text-primary text-xl"
          onClick={() => switchMode()}
        >
          {!isCourse ? "Courses" : "Lessons"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ExploreCourseTaskSwitch;
