import React from "react";
import { Col, Row } from "react-bootstrap";
import { ExploreTaskCard } from "../task/TaskCard";

const TaskCardsFlat = ({
  taskList,
  handleClick = () => {},
  isRowNoWrap = false,
  isSchoolCoursePurchaseModel,
  purchasedCourseList,
}) => {
  const purchasedCourseRefList = purchasedCourseList?.map(
    (item) => item.courseRef
  );
  const isCoursePurchased = (course) => {
    return (
      isSchoolCoursePurchaseModel &&
      purchasedCourseRefList?.includes(course.courseRef)
    );
  };
  return (
    <Row style={isRowNoWrap ? { flexWrap: "nowrap" } : null}>
      {taskList.map((item) => {
        const { task, course, project } = item;
        return (
          <Col
            key={task.taskRef}
            xl={3}
            lg={4}
            sm={6}
            xs={12}
            itemID={task.taskRef}
          >
            <ExploreTaskCard
              task={task}
              course={course}
              project={project}
              isPurchased={isCoursePurchased(course)}
              onClick={() =>
                handleClick({
                  task: task,
                  course: course,
                  project: project,
                  shouldPurchaseCourse:
                    isSchoolCoursePurchaseModel && !isCoursePurchased(course),
                })
              }
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default TaskCardsFlat;
