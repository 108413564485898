import React, { useState, useEffect, useContext } from "react";
import {
  AccordionContext,
  Button,
  Card,
  Col,
  Modal,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadCourseCurriculum, loadTaskOverview } from "../../actions";
import {
  AWS_REGION_COUNTRY_MAP,
  COUNTRY_INFO,
  EXPLORE_PAGE_MODES,
  GA_ACTIONS,
} from "../../utils/constants";
import { findRanges } from "../../utils/GenericUtils";
import ImageHeaderWithTitle from "../util/ImageHeaderWithTitle";
import ModalButton from "../util/ModalButton";
import { sendGAExploreEvent } from "../../utils/thirdParty/GoogleAnalyticsUtils";
import camelCaseRecursive from "../../utils/CamelCaseRecursive";
import PublicRedirectSignupModal from "../util/PublicRedirectSignupModal";
import { requestJsonObject } from "../../api/apis";
import "../../style/antd-image-preview.css";
import TeacherPurchaseCourseModal from "../util/TeacherPurchaseCourseModal";
import TaskOverviewContentBlock from "./TaskOverviewContentBlock";
import TaskOverviewSkillsBlock from "./TaskOverviewSkillsBlock";
import {
  ExploreCourseHorizontalCard,
} from "../course/CourseCard";

const REGION_STR = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
const YEAR_LEVEL_PREFIX = COUNTRY_INFO[REGION_STR].yearLevelPrefix;

const OverviewTaskModal = ({
  show,
  setShow,
  isPublic = false,
  calledFrom = EXPLORE_PAGE_MODES.EXPLORE,
}) => {
  const userId = useSelector((state) => state.auth.userDetail?.id);
  const task = show && show.task;
  const course = show && show.course;
  const project = show && show.project;
  const metadata = course && course.countryMetadata[REGION_STR];
  const shouldPurchaseCourse = show && show.shouldPurchaseCourse;

  useEffect(() => {
    if (show && course.courseRef) {
      sendGAExploreEvent(
        userId,
        GA_ACTIONS.EXPLORE_SEARCH.CLICK_OVERVIEW,
        task.taskRef,
        calledFrom
      );
    }
  }, [show]);

  const TaskAccordion = ({ task, index, sectionIndex, noBorder }) => {
    const currentEventKey = useContext(AccordionContext);
    const eventKey = `${sectionIndex}.${index}`;
    const isCurrentEventKey = currentEventKey === eventKey;
    const [rubrics, setRubrics] = useState(null);

    const handleLoadTaskDetailSuccess = (data) => {
      let rubrics = data.taskResourceBlockList.map((block) => {
        const { rubric } = block;
        if (!rubric) {
          return null;
        }
        return {
          codes: rubric.curriculumCodes.au.map((s) => s.trim()).join(" | "),
          criteria: rubric.criteria.trim(),
        };
      });
      rubrics = rubrics
        .filter((e) => e !== null)
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.codes === value.codes && t.criteria === value.criteria
            )
        );
      setRubrics(rubrics);
    };

    useEffect(() => {
      // if (currentEventKey === eventKey && !taskDetail) {
      if (isPublic) {
        const { CONTENT_BUCKET_CDN_DOMAIN } = window._env_;
        requestJsonObject(
          CONTENT_BUCKET_CDN_DOMAIN +
            "/public/content/task/" +
            task.taskRef +
            ".json",
          (data) => {
            handleLoadTaskDetailSuccess(camelCaseRecursive(data));
          }
        );
        return;
      }
      loadTaskOverview(task.taskRef, handleLoadTaskDetailSuccess);
      // }
    }, [isCurrentEventKey]);

    return (
      rubrics &&
      rubrics.length > 0 && (
        <Card className="bg-white shadow-none">
          <Card.Body>
            <div className="text-dark">
              <>
                <div className="text-primary text-sm mb-1 mt-0 font-weight-bolder">
                  CURRICULUM MAPPING
                </div>
                {rubrics.map((rubric, index) => (
                  <div
                    key={`${task.taskRef}-rubrics-${index}`}
                    className={`${index === 0 ? "" : " mt-3"}`}
                  >
                    <span className="font-weight-bolder">{rubric.codes}</span>
                    <br />
                    {rubric.criteria}
                  </div>
                ))}
              </>
            </div>
          </Card.Body>
        </Card>
      )
    );
  };

  const BelongedCourseSection = () => {
    return (
      <Card className="bg-white shadow-none">
        <Card.Body>
          <div className="text-dark">
            <>
              <h5 className="text-primary mb-4">This lesson is part of:</h5>
              <ExploreCourseHorizontalCard
                course={course}
                project={project}
                isPublic={isPublic}
                shouldPurchaseCourse={shouldPurchaseCourse}
              />
            </>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const MetadataButtons = () => {
    const yearLevelStr = `${YEAR_LEVEL_PREFIX} ${findRanges(metadata.levels)}`;
    const suggestedTime = task.suggestedTime + " mins";
    return (
      <>
        {[...metadata.subject, yearLevelStr, suggestedTime].map((text, i) => (
          <span key={i} className="mr-2">
            {[
              { size: "sm", className: "d-lg-inline d-none" },
              { size: "xs", className: "d-lg-none" },
            ].map((item, j) => (
              <Button
                key={i + "-" + j}
                className={item.className}
                variant="outline-light rounded-pill text-white"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  cursor: "auto",
                }}
                size={item.size}
              >
                {text}
              </Button>
            ))}
          </span>
        ))}
      </>
    );
  };

  const ActionButtons = ({ courseRef }) => {
    return (
      <div className="text-lg-right mb-3">
        {shouldPurchaseCourse && (
          <ModalButton
            variant="warning text-white rounded-pill mb-2"
            buttonText={<>Purchase Lesson</>}
            animation={false}
            noHeader={true}
            centered={true}
            size="sm"
            handleButtonClick={() =>
              sendGAExploreEvent(
                userId,
                GA_ACTIONS.EXPLORE_SEARCH.CLICK_PURCHASE,
                courseRef,
                calledFrom
              )
            }
          >
            <TeacherPurchaseCourseModal courseRef={courseRef} />
          </ModalButton>
        )}
        {!shouldPurchaseCourse && (
          <>
            {isPublic ? (
              <ModalButton
                variant="primary rounded-pill mb-2"
                buttonText="View Full Lesson Content"
                animation={!isPublic}
                noHeader={isPublic}
                centered={isPublic}
                size="sm"
                handleButtonClick={() =>
                  sendGAExploreEvent(
                    userId,
                    GA_ACTIONS.EXPLORE_SEARCH.CLICK_PREVIEW,
                    courseRef,
                    calledFrom
                  )
                }
              >
                <PublicRedirectSignupModal
                  actionStr="view this lesson's full content"
                  courseRef={courseRef}
                  calledFrom="View Full Lesson Content"
                  taskMode={true}
                />
              </ModalButton>
            ) : (
              <Button
                variant="primary rounded-pill mb-2"
                size="sm"
                onClick={() => {
                  sendGAExploreEvent(
                    userId,
                    GA_ACTIONS.EXPLORE_SEARCH.CLICK_PREVIEW,
                    courseRef,
                    calledFrom
                  );
                  window
                    .open(`/task-preview/${task.taskRef}`, "_blank")
                    .focus();
                }}
              >
                View Full Lesson Content
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    show && (
      <Modal size="xl" show={show} onHide={() => setShow(null)}>
        <Modal.Header
          closeButton={false}
          className="p-0"
          style={{ position: "relative" }}
        >
          <ImageHeaderWithTitle
            title={task.title}
            thumbnail={task.thumbnail}
            customContainerStyle={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderTopLeftRadius: "0.375rem",
              borderTopRightRadius: "0.375rem",
            }}
          />
          {/* TODO: Optimise this by using styles/css */}
          {[
            { size: "sm", className: "d-lg-inline d-none" },
            { size: "xs", className: "d-lg-none" },
          ].map((item, index) => (
            <Button
              key={`back-button-${index}`}
              size={item.size}
              className={item.className}
              variant="outline-light rounded-circle btn-icon-only m-xl-3 m-1 text-white"
              style={{
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              }}
              onClick={() => setShow(null)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          ))}
          <div
            className="d-none d-sm-block text-center text-dark w-100 mb-2 mb-lg-3"
            style={{ position: "absolute", bottom: 0 }}
          >
            <MetadataButtons />
          </div>
        </Modal.Header>
        <div className="d-sm-none text-center text-dark w-100 my-2">
          <MetadataButtons />
        </div>
        <hr className="d-sm-none text-dark p-0 m-0" />
        <Modal.Body>
          <ActionButtons taskRef={task.taskRef} />
          <Card>
            <Card.Body className="text-dark">
              <h5 className="text-primary mb-4">Lesson Overview</h5>
              <div className="mt-4" style={{ whiteSpace: "pre-wrap" }}>
                {task.description}
              </div>
              {task.contentMetadata && (
                <>
                  <div className="my-4">In this task, students will:</div>
                  <Row>
                    <Col md={6}>
                      <TaskOverviewContentBlock
                        contentList={task.contentMetadata?.overview?.content}
                      />
                    </Col>
                    <Col md={6}>
                      <TaskOverviewSkillsBlock
                        skillsList={task.contentMetadata?.overview?.skills}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
          <TaskAccordion task={task} />
          <BelongedCourseSection />
          <ActionButtons courseRef={course.courseRef} />
        </Modal.Body>
      </Modal>
    )
  );
};

export default OverviewTaskModal;
