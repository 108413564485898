import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/util/Header";
import { hasUserSchoolPurchasedTask } from "../../actions";
import { useSelector } from "react-redux";
import { PURCHASE_MODEL_TYPE } from "../../utils/constants";
import SingleTaskView from "../../components/task/SingleTaskView";

const TaskPreviewPage = () => {
  const { taskRef } = useParams();
  const userDetailData = useSelector((state) => state.auth.userDetail);
  const [isLoading, setIsLoading] = useState(true);
  const isSchoolCoursePurchaseModel =
    userDetailData?.school?.purchaseModel ===
    PURCHASE_MODEL_TYPE.COURSE_PURCHASE;
  useEffect(() => {
    if (!userDetailData) {
      return;
    }
    if (userDetailData && !isSchoolCoursePurchaseModel) {
      setIsLoading(false);
      return;
    }
    const data = {
      taskRef,
    };
    hasUserSchoolPurchasedTask(
      data,
      (data) => {
        if (!data.hasPurchased) {
          window.location.href = "/access-denied";
        } else {
          setIsLoading(false);
        }
      },
      () => {
        window.location.href = "/access-denied";
      }
    );
  }, [taskRef, userDetailData]);

  return (
    <>
      <Header />
      {!isLoading && userDetailData && (
        <SingleTaskView taskRef={taskRef} isPreview={true} />
      )}
    </>
  );
};

export default TaskPreviewPage;
