import rest from "../api/apis";
import snakeCaseKeys from "snakecase-keys";
import { DISPLAY_TASK_LINK, SESSION_EXPIRED } from "./types";
import camelCaseRecursive from "../utils/CamelCaseRecursive";
import { store } from "../index.js";

const genericRequest = (
  url,
  data,
  onRequestSuccess,
  onRequestFail,
  shouldAuthErrorLogout = true,
  shouldUseToken = true,
  shouldAuthErrorRefresh = false
) => {
  const requestAction = async () => {
    let response = null;
    try {
      if (data)
        response = await rest(shouldUseToken).post(url, {
          ...snakeCaseKeys(data, {
            exclude: [new RegExp("-")],
          }),
        });
      else response = await rest(shouldUseToken).post(url);
    } catch (err) {
      // If error is 401 authentication, logout.
      if (
        shouldAuthErrorLogout &&
        err.response &&
        err.response.status === 401
      ) {
        store.dispatch({ type: SESSION_EXPIRED, payload: true });
        if (shouldAuthErrorRefresh) {
          document.cookie = "escs=; exp=; Max-Age=-99999999;";
          window.onbeforeunload = null;
          window.location.href = "/";
        }
      }
      console.log("############# err");
      console.log(err);
      if (onRequestFail) onRequestFail(err.response.data);

      return;
    }

    const responseData =
      "data" in response.data
        ? camelCaseRecursive(response.data.data)
        : response.data;
    if (response.data.success) onRequestSuccess(responseData);
    else {
      if (onRequestFail) onRequestFail(responseData);
    }
  };
  requestAction();
};

const dipatchWithType = (
  dispatch,
  type,
  payload,
  shouldPayload2CamelCase = true
) => {
  if (shouldPayload2CamelCase) {
    payload = camelCaseRecursive(payload);
  }
  dispatch({ type: type, payload: payload });
  return;
};

// Authentication Related
export const signIn = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/login/",
    data,
    onRequestSuccess,
    onRequestFail,
    false,
    false
  );
};

export const microsoftSignIn = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/login-microsoft-entra-id/",
    data,
    onRequestSuccess,
    onRequestFail,
    false,
    false
  );
};

export const otpSignIn = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/otp-login/",
    data,
    onRequestSuccess,
    onRequestFail,
    false,
    false
  );
};

export const otpResend = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/otp-resend/",
    data,
    onRequestSuccess,
    onRequestFail,
    true,
    false
  );
};

export const signUp = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/signup/",
    data,
    onRequestSuccess,
    onRequestFail,
    false,
    false
  );
};

export const otpSignUp = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/otp-signup/",
    data,
    onRequestSuccess,
    onRequestFail,
    false,
    false
  );
};

export const otpSignUpResend = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/otp-signup-resend/",
    data,
    onRequestSuccess,
    onRequestFail,
    false,
    false
  );
};

export const passwordResetSendEmail = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/password-reset-request/",
    data,
    onRequestSuccess,
    onRequestFail,
    true,
    false
  );
};

export const passwordResetCreate = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/password-reset-create/",
    data,
    onRequestSuccess,
    onRequestFail,
    true,
    false
  );
};

// User Related

export const loadCurrentUserDetail = (
  onRequestSuccess,
  onRequestFail,
  shouldAuthErrorLogout = true,
  shouldAuthErrorRefresh = false
) => {
  genericRequest(
    "/user/get-logged-in/",
    null,
    onRequestSuccess,
    onRequestFail,
    shouldAuthErrorLogout,
    true,
    shouldAuthErrorRefresh
  );
};

export const loadUserRegisterStatus = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/user/get-reg-status/",
    data,
    onRequestSuccess,
    onRequestFail,
    true,
    false
  );
};

export const loadUserList = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/user/list/", data, onRequestSuccess, onRequestFail);
};

export const createUsers = (data, onRequestSuccess, onRequestFail) => {
  // Example:
  // {
  //     "schoolRef": "00000000-0000-0000-0000-000000000015",
  //     "userList": [
  //         {
  //             "firstName": "s1firstname",
  //             "lastName": "s1lastname",
  //             "email": "s1@testedustem.com",
  //             "roleType": "STUDENT",
  //             "coursExecRef": "00000000-0000-0000-0000-00000000006f"
  //         }
  //     ]
  // }
  genericRequest("/user/create/", data, onRequestSuccess, onRequestFail);
};

export const createSBUAdmin = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/user/create-csm/", data, onRequestSuccess, onRequestFail);
};

export const deleteUser = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/user/delete/", data, onRequestSuccess, onRequestFail);
};

export const deactivateUser = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/user/deactivate/", data, onRequestSuccess, onRequestFail);
};

export const updateProfile = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/user/update/", data, onRequestSuccess, onRequestFail);
};

export const updatePlanAndSchool = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/user/update-plan-and-school/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const updatePassword = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/user/update-password/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

// Project Related

export const loadProjectDetail = (
  projectRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { projectRef: projectRef };
  genericRequest("/project/get/", data, onRequestSuccess, onRequestFail);
};

export const loadProjectList = (onRequestSuccess, onRequestFail) => {
  genericRequest("/project/list/", null, onRequestSuccess, onRequestFail);
};

export const deleteProject = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/project/delete/", data, onRequestSuccess, onRequestFail);
};

export const createProject = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/project/create/", data, onRequestSuccess, onRequestFail);
};

export const updateProject = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/project/update/", data, onRequestSuccess, onRequestFail);
};

// Course Detail Related

export const loadCourseList = (projectRef, onRequestSuccess, onRequestFail) => {
  const data = { projectRef: projectRef };
  genericRequest("/course/list/", data, onRequestSuccess, onRequestFail);
};

export const loadSearchableCourseList = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/course/list-searchable/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const loadCourseDetail = (
  courseRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { courseRef: courseRef };
  genericRequest("/course/get/", data, onRequestSuccess, onRequestFail);
};

export const duplicateCourse = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/course/duplicate/", data, onRequestSuccess, onRequestFail);
};

export const deleteCourse = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/course/delete/", data, onRequestSuccess, onRequestFail);
};

export const loadUserCourseList = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/course/list-by-user-ref/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const duplicateUserCourse = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/course/user-duplicate/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const archiveCourse = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/course/archive/", data, onRequestSuccess, onRequestFail);
};

export const createCourse = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/course/create/", data, onRequestSuccess, onRequestFail);
};

export const updateCourse = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/course/update/", data, onRequestSuccess, onRequestFail);
};

export const updateCourseIsAssignable = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/course/update-is-assignable/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

// User Course Link Related

export const loadUserCourseLinkDetail = (
  userCourseLinkRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { userCourseLinkRef: userCourseLinkRef };
  genericRequest(
    "/user-course-link/get/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const existsUserCourseLink = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/user-course-link/exists/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const listUserCourseLink = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/user-course-link/list-by-course-ref/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const createUserCourseLink = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/user-course-link/create/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const deleteUserCourseLink = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/user-course-link/delete/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};
export const updateFavourite = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/course/update-favourite/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const loadFavouriteList = (onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/course/list-favourite/",
    null,
    onRequestSuccess,
    onRequestFail
  );
};

// Course Curriculum Related

export const loadCourseCurriculum = (
  courseRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = {
    courseRef: courseRef,
  };
  genericRequest(
    "/course/get-curriculum/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const updateCourseCurriculum = (
  newCourseCurriculumData,
  onRequestSuccess,
  onRequestFail
) => {
  let updatedCurriculumData = {
    courseRef: newCourseCurriculumData.courseDetail.courseRef,
    courseTaskLinks: newCourseCurriculumData.courseTaskLinks,
    sectionOrder: newCourseCurriculumData.sectionOrder,
    sections: newCourseCurriculumData.sections,
  };
  updatedCurriculumData = snakeCaseKeys(updatedCurriculumData, {
    exclude: [new RegExp("-")],
  });
  const data = updatedCurriculumData;

  genericRequest(
    "/course/update-curriculum/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const userUpdateCourseCurriculum = (
  newCourseCurriculumData,
  onRequestSuccess,
  onRequestFail
) => {
  let updatedCurriculumData = {
    courseRef: newCourseCurriculumData.courseDetail.courseRef,
    courseTaskLinks: newCourseCurriculumData.courseTaskLinks,
    sectionOrder: newCourseCurriculumData.sectionOrder,
    sections: newCourseCurriculumData.sections,
  };
  updatedCurriculumData = snakeCaseKeys(updatedCurriculumData, {
    exclude: [new RegExp("-")],
  });
  const data = updatedCurriculumData;

  genericRequest(
    "/course/user-update-curriculum/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

// Task Related

export const switchDisplayTaskLink =
  (courseTaskLink) => (dispatch, getState) => {
    dipatchWithType(dispatch, DISPLAY_TASK_LINK, courseTaskLink);
  };

export const loadTaskDetail = (taskRef, onRequestSuccess, onRequestFail) => {
  const data = { taskRef: taskRef };
  genericRequest("/task/get/", data, onRequestSuccess, onRequestFail);
};

export const loadTaskOverview = (taskRef, onRequestSuccess, onRequestFail) => {
  const data = { taskRef: taskRef };
  genericRequest("/task/get-overview/", data, onRequestSuccess, onRequestFail);
};

export const loadTaskList = (onRequestSuccess, onRequestFail) => {
  genericRequest("/task/list/", null, onRequestSuccess, onRequestFail);
};

export const loadSearchableTaskList = (onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/task/list-searchable/",
    null,
    onRequestSuccess,
    onRequestFail
  );
};

export const loadTaskVersionList = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/task/list-versions/", data, onRequestSuccess, onRequestFail);
};

export const deleteTask = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/task/delete/", data, onRequestSuccess, onRequestFail);
};

export const createTask = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/task/create/", data, onRequestSuccess, onRequestFail);
};

export const updateTask = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/task/update/", data, onRequestSuccess, onRequestFail);
};

export const userUpdateTask = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/task/user-update/", data, onRequestSuccess, onRequestFail);
};

export const duplicateTask = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/task/duplicate/", data, onRequestSuccess, onRequestFail);
};

export const markTaskVersion = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/task/mark-version/", data, onRequestSuccess, onRequestFail);
};

export const loadActiveTaskCourseLinkList = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/task/list-active-course-links/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const loadTaskExistsActiveCourseExec = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/task/is-in-active-courseexec/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

// School Related

export const loadSchoolDetail = (
  schoolRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { schoolRef: schoolRef };
  genericRequest("/school/get/", data, onRequestSuccess, onRequestFail);
};

export const loadSchoolList = (onRequestSuccess, onRequestFail) => {
  genericRequest("/school/list/", null, onRequestSuccess, onRequestFail);
};

export const deleteSchool = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/school/delete/", data, onRequestSuccess, onRequestFail);
};

export const createSchool = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/school/create/", data, onRequestSuccess, onRequestFail);
};

export const updateSchool = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/school/update/", data, onRequestSuccess, onRequestFail);
};

// UserSchoolLink Related
export const loadUserSchoolLinkList = (
  schoolRef,
  roleTypeName,
  onRequestSuccess,
  onRequestFail
) => {
  // Example:
  // {
  //   schoolRef: 1,
  //   roleTypeName: "TEACHER"
  // }
  const data = {
    schoolRef: schoolRef,
    roleTypeName: roleTypeName,
  };
  genericRequest(
    "/user-school-link/list/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const loadUserSchoolLinkDetail = (
  userSchoolLinkRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { userSchoolLinkRef: userSchoolLinkRef };
  genericRequest(
    "/user-school-link/get/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

// CourseExec Related
export const loadCourseExecList = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/course-exec/list/", data, onRequestSuccess, onRequestFail);
};

export const loadUserEnrolmentList = (onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/course-exec/list-user-enrolled/",
    null,
    onRequestSuccess,
    onRequestFail
  );
};

export const loadCourseExecDetail = (
  courseExecRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { courseExecRef: courseExecRef };
  genericRequest("/course-exec/get/", data, onRequestSuccess, onRequestFail);
};

export const createCourseExec = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/course-exec/create/", data, onRequestSuccess, onRequestFail);
};

export const updateCourseExec = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/course-exec/update/", data, onRequestSuccess, onRequestFail);
};

export const deleteCourseExec = (
  courseExecRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { courseExecRef: courseExecRef };
  genericRequest("/course-exec/delete/", data, onRequestSuccess, onRequestFail);
};

export const archiveCourseExec = (
  courseExecRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { courseExecRef: courseExecRef };
  genericRequest(
    "/course-exec/archive/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const unarchiveCourseExec = (
  courseExecRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { courseExecRef: courseExecRef };
  genericRequest(
    "/course-exec/unarchive/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const getProgressCourseExec = (
  courseExecRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { courseExecRef: courseExecRef };
  genericRequest(
    "/course-exec/get-progress/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

// UserCourseExecLink Related

export const listUserCourseExecLink = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  // Can add more filters
  // Example:
  // {
  //   courseExecRef: 123,
  //   roleTypeName: "TEACHER",
  // }
  genericRequest(
    "/user-course-exec-link/list/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const inviteUserToCourseExec = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  // Can add more filters
  // Example:
  // {
  //   courseExecRef: 123,
  //   users: ["XXX-XXX-XX"],
  // }
  genericRequest(
    "/user-course-exec-link/send-invitation/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const loadUserCourseExecLinkDetail = (
  userCourseExecLinkRef,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { userCourseExecLinkRef: userCourseExecLinkRef };
  genericRequest(
    "/user-course-exec-link/get/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const createUserCourseExecLink = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  // Example data:
  // {
  //   "course_exec_ref": "00000000-0000-0000-0000-00000000006f",
  //   "users": ["3c3b399d-3351-49e8-b3b7-9126c54f5a59"]
  // }
  genericRequest(
    "/user-course-exec-link/create/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const deleteUserCourseExecLink = (
  userCourseExecLinkRefs,
  onRequestSuccess,
  onRequestFail
) => {
  const data = { userCourseExecLinkRefs: userCourseExecLinkRefs };
  genericRequest(
    "/user-course-exec-link/delete/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const updateLastViewedCourseTaskLink = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  // Example data:
  // {
  //   "course_exec_ref": "00000000-0000-0000-0000-00000000006f",
  //   "course_task_link_ref": "00000000-0000-0000-0000-00000000003d"
  // }
  genericRequest(
    "/user-course-exec-link/update-last-viewed/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const getActiveUserCourseTaskLinkCount = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/user-course-exec-link/get-active-link-count/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

// Student Work Related

export const listTaskResourceBlockWork = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/student-work/list-task-resource-block-work/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const existsTaskResourceBlockWork = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/student-work/exists-task-resource-block-work/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const updateTaskResourceBlockWork = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/student-work/update-task-resource-block-work/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const assessTaskResourceBlockWork = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/student-work/assess-task-resource-block-work/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const listTRBWorkAllUsers = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/student-work/list-task-resource-block-work-all-users/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const listTRBWorkCourseExecSingleUser = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/student-work/list-task-resource-block-work-course-exec-single-user/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

// Task Feedback Related
export const existsTaskFeedback = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/feedback/task/exists/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const createTaskFeedback = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/feedback/task/create/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const getTaskFeedback = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/feedback/task/get/", data, onRequestSuccess, onRequestFail);
};

export const createUpdateUserTaskFeedback = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/feedback/task/user-create-update/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

// Report Related

export const getTaskFeedbackReport = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest("/report/feedback/", data, onRequestSuccess, onRequestFail);
};

export const getClassroomReport = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/report/classroom/", data, onRequestSuccess, onRequestFail);
};

export const getTeacherReport = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/report/teacher/", data, onRequestSuccess, onRequestFail);
};

export const getUserNumberReport = (data, onRequestSuccess, onRequestFail) => {
  genericRequest("/report/user-number/", data, onRequestSuccess, onRequestFail);
};

// Analytics/Insight Related
export const getClassroomAnalyticsList = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/analytics/course-exec-list/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const getClassroomTimeline = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/analytics/course-exec-timeline/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const getSchoolSummary = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/analytics/school-summary/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};
export const getSchoolTeacherActivity = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/analytics/school-teacher-activity/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};
export const getSchoolCourseExecCalendar = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/analytics/school-course-exec-calendar/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};
export const getAllTeacherExplore = (data, onRequestSuccess, onRequestFail) => {
  genericRequest(
    "/analytics/all-teacher-explore/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const getAllSchoolPurchasedCourseLinks = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/school-purchased-course-link/list-by-school-ref/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const hasUserSchoolPurchasedCourse = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/school-purchased-course-link/has-user-school-purchased-course/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const hasUserSchoolPurchasedTask = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/school-purchased-course-link/has-user-school-purchased-task/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const createSchoolPurchasedCourseLink = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/school-purchased-course-link/create/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const bulkCreateSchoolPurchasedCourseLink = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/school-purchased-course-link/bulk-create/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};

export const deleteSchoolPurchasedCourseLinks = (
  data,
  onRequestSuccess,
  onRequestFail
) => {
  genericRequest(
    "/school-purchased-course-link/delete/",
    data,
    onRequestSuccess,
    onRequestFail
  );
};
