import MiniSearch from "minisearch";

export const buildMiniSearchIndex = (courseList, projectList) => {
  let miniSearch = new MiniSearch({
    fields: ["textSearchMerged", "title"], // fields to index for full-text search
    storeFields: [
      "courseRef",
      "contentMetadata",
      "countryMetadata",
      "courseRef",
      "courseType",
      "createdAt",
      "id",
      "project",
      "projectRef",
      "textSearchMerged",
      "thumbnail",
      "studentSuccessMetadata",
    ], // fields to return with search results
  });
  const indexCourses = courseList.map((course) => {
    const project = projectList[course.projectRef];
    return {
      ...course,
      id: course.courseRef,
      // For array like search, check this https://github.com/lucaong/minisearch/issues/63
      textSearchMerged:
        project.title + " " + course?.contentMetadata?.keyword?.join(" ") ?? "",
      project: project,
      title: project.title,
    };
  });
  // Index all documents
  miniSearch.addAll(indexCourses);
  return miniSearch;
};

export const buildTasksMiniSearchIndex = (taskDisplayList) => {
  let miniSearch = new MiniSearch({
    fields: ["textSearchMerged", "title"], // fields to index for full-text search
    storeFields: [
      "taskRef",
      "courseRef",
      "contentMetadata",
      "countryMetadata",
      "courseRef",
      "courseType",
      "createdAt",
      "id",
      "project",
      "task",
      "course",
      "projectRef",
      "textSearchMerged",
      "thumbnail",
      "studentSuccessMetadata",
    ], // fields to return with search results
  });
  const indexCourses = taskDisplayList.map((item) => {
    const { task, course, project } = item;
    // task.contentMetadata is null or a format like this {"overview": {"skills": [{"title": "jijiojij", "description": "afwefwef"}, {"title": "efwe"}], "content": ["kjlkjoij"]}}
    // return skills and content as a single string for search
    const overview = task.contentMetadata?.overview;
    const skills =
      overview?.skills?.map((skill) => skill.title + " " + skill.description) ??
      [];
    const content = overview?.content ?? [];
    const textSearchMerged = (
      task.title +
      " " +
      skills?.join(" ") +
      " " +
      content?.join(" ")
    ).trim();

    return {
      taskRef: task.taskRef,
      ...course,
      id: task.taskRef,
      // For array like search, check this https://github.com/lucaong/minisearch/issues/63
      textSearchMerged: textSearchMerged,
      project: project,
      task: task,
      course: course,
      title: task.title,
    };
  });
  // Index all documents
  miniSearch.addAll(indexCourses);
  return miniSearch;
};
