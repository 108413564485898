import React from "react";
import { COUNTRY_INFO } from "../../utils/constants";
import { Card, Badge } from "react-bootstrap";
import Can from "../../utils/Can";
import { AWS_REGION_COUNTRY_MAP } from "../../utils/constants";
import { findRanges } from "../../utils/GenericUtils";
// import FavouriteActionButton from "../explore/FavouriteActionButton";

export const ExploreTaskCard = ({
  task,
  course,
  project,
  isPurchased,
  onClick = () => {},
}) => {
  const region = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
  const metadata = course.countryMetadata[region];

  const CardBodyContent = () => {
    const CourseTag = (props) => (
      <div
        className="px-2 bg-warning rounded mr-1 mb-1 text-truncate"
        style={{ maxWidth: "calc(50% - 0.5rem)" }}
      >
        {props.children}
      </div>
    );
    return (
      <div className="overflow-hidden" style={{ height: "10.75rem" }}>
        <Card.Title
          className="d-flex overflow-hidden mb-2 mt-2"
          style={{
            maxHeight: "3.125rem",
          }}
        >
          {task.title}
        </Card.Title>
        <div
          className="d-flex text-white text-sm my-1"
          style={{ flexWrap: "wrap" }}
        >
          {metadata.subject &&
            metadata.subject.map((subject, index) => {
              return <CourseTag key={index}>{subject}</CourseTag>;
            })}
          {metadata.levels && metadata.levels.length > 0 && (
            <CourseTag>
              {`${COUNTRY_INFO[region].yearLevelPrefix} ${findRanges(
                metadata.levels
              )}`}
            </CourseTag>
          )}
          {/* {course.courseType && (
            <CourseTag>{CourseTypeDisplayText[course.courseType]}</CourseTag>
          )} */}
          {task.suggestedTime && (
            <CourseTag>{task.suggestedTime} min</CourseTag>
          )}
        </div>
        <div className="mt-2">
          <div
            className="text-sm overflow-hidden"
            style={{
              maxHeight: "6rem",
            }}
          >
            {task.description}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card
      className="border-0 card-hover-overlay hover-shadow-lg cursor-pointer no-text-select"
      style={{
        boxShadow: "0 0.25rem 0.5rem rgb(18 38 63 / 10%)",
      }}
    >
      <div className="image-wrapper-43" onClick={onClick}>
        <Card.Img variant="top" src={task.thumbnail} />
      </div>
      <Can
        perform="favourite-course:teacher"
        yes={() => (
          <>
            {isPurchased && (
              <div className="mr-2 mt-3 pr-1" style={{ position: "absolute" }}>
                <Badge variant="warning" className="text-white">
                  Purchased
                </Badge>
              </div>
            )}
          </>
        )}
        no={() => null}
      />
      {/*  <Can
        perform="favourite-course:teacher"
        yes={() => (
          <div
            className="mr-2 mt-2 pr-1 pt-1"
            style={{ position: "absolute", right: 0 }}
          >
            <div className="d-flex">
              <FavouriteActionButton
                courseRef={course.courseRef}
                title={project.title}
              />
            </div>
          </div>
        )}
        no={() => null}
      /> */}
      <Card.Body className="pt-3" onClick={onClick}>
        <CardBodyContent />
      </Card.Body>
    </Card>
  );
};
