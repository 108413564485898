import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import {
  AWS_REGION_COUNTRY_MAP,
  COUNTRY_INFO,
  CourseType,
  CourseTypeDisplayText,
  EXPLORE_PAGE_MODES,
  ILLUSTRATIONS,
} from "../../utils/constants";
import { getGenericReactSelectOption } from "../../utils/GenericUtils";
import { useAsyncDebounce } from "react-table";
import BoldHeaderWithSideItems from "../util/BoldHeaderWithSideItems";
import OverviewModal from "./OverviewModal";
import { sendGASearchEvent } from "../../utils/thirdParty/GoogleAnalyticsUtils";
import BackToExploreButton from "./BackToExploreButton";
import CourseCardsFlat from "./CourseCardsFlat";
import { buildMiniSearchIndex } from "../../utils/miniSearch/MiniSearchUtils";
import MiniSearch from "minisearch";
import ExploreCourseTaskSwitch from "./ExploreCourseTaskSwitch";

const REGION_STR = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
const YEAR_LEVEL_PREFIX = COUNTRY_INFO[REGION_STR].yearLevelPrefix;

const NoSearchResultInfo = () => (
  <div className="text-dark text-center mt-xl-7 mt-md-10 mt-4">
    <h5>
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className="text-primary text-lg mr-3"
      />
      Oops! No results found.
    </h5>
    <>
      Looks like you have applied too many filters. <br />
      Please clear some to get better search results.
    </>
    {[
      { className: "d-lg-none d-block w-75", style: {} },
      { className: "d-none d-lg-block", style: { width: "40%" } },
    ].map((item, index) => (
      <img
        key={`responsive-${index}`}
        alt="0 result"
        src={ILLUSTRATIONS.ZERO_RESULT.SEARCH_COURSE}
        className={item.className + " mx-auto"}
        style={item.style}
      />
    ))}
  </div>
);

const SearchPanel = ({
  courseList,
  setMode,
  defaultFilter,
  isSchoolCoursePurchaseModel,
  purchasedCourseList,
  setRedirectFilter,
  isPublic = false,
}) => {
  const userId = useSelector((state) => state.auth.userDetail?.id);
  const LOAD_NUM_INCREMENT = 8;
  const INIT_FILTER = {
    text: null,
    levels: [],
    subject:
      defaultFilter && "subject" in defaultFilter
        ? [defaultFilter.subject]
        : [],
    onlyShowPurchased:
      isSchoolCoursePurchaseModel && defaultFilter?.onlyShowPurchased
        ? defaultFilter?.onlyShowPurchased
        : null,
  };

  const [showCourseModal, setShowCourseModal] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(INIT_FILTER);
  const [loadNumber, setLoadNumber] = useState(LOAD_NUM_INCREMENT);
  const textRef = useRef();
  const subjectRef = useRef();
  const levelsRef = useRef();
  const durationRef = useRef();
  const purchasedRef = useRef();

  const [filteredCourseList, setFilteredCourseList] = useState(
    courseList.courses
  );

  const purchasedCourseRefList = purchasedCourseList?.map(
    (item) => item.courseRef
  );

  const miniSearchIndex = useMemo(() => {
    return buildMiniSearchIndex(
      courseList.courses,
      courseList.projectDict,
      REGION_STR
    );
  }, [courseList]);

  const searchByMiniSearch = (filter) => {
    const filterFunction = (item) => {
      let result = true;
      if (filter.courseType) {
        result = result && item.courseType === filter.courseType;
      }
      if (filter?.levels?.length > 0) {
        result =
          result &&
          filter.levels.some((level) =>
            item.countryMetadata[REGION_STR].levels.includes(level)
          );
      }
      if (filter?.subject?.length > 0) {
        result =
          result &&
          filter.subject.some((subject) =>
            item.countryMetadata[REGION_STR].subject.includes(subject)
          );
      }
      if (filter?.onlyShowPurchased !== null) {
        if (filter.onlyShowPurchased) {
          result = result && purchasedCourseRefList?.includes(item.courseRef);
        } else {
          result = result && !purchasedCourseRefList?.includes(item.courseRef);
        }
      }
      return result;
    };

    const result = miniSearchIndex.search(filter.text ?? MiniSearch.wildcard, {
      prefix: (term) => term.length > 0,
      fuzzy: (term) => (term.length > 3 ? 0.2 : null),
      filter: filterFunction,
      boost: { title: 2 },
    });
    return result;
  };

  useEffect(() => {
    sendGASearchEvent(userId, currentFilter);
    setLoadNumber(LOAD_NUM_INCREMENT);
    setFilteredCourseList(filterCourseList(currentFilter));
  }, [currentFilter]);

  const filterCourseList = (filter) => {
    if (!filter) return courseList.courses;

    return searchByMiniSearch(filter);
  };

  const onTextChange = useAsyncDebounce((e) => {
    setCurrentFilter({
      ...currentFilter,
      text: e.target.value || null,
    });
  }, 500);

  const customStyles = { height: "2.575rem" };

  const LoadMoreButtonSection = ({ loadNumber, total }) => {
    return (
      (total > 0 || loadNumber < total) && (
        <div className="text-center py-5">
          {total > 0 && (
            <div className="mb-3 font-weight-bold">
              Showing {loadNumber < total ? loadNumber : total} of {total}{" "}
              courses
            </div>
          )}
          {loadNumber < total && (
            <Button
              variant="primary rounded-pill"
              onClick={() => setLoadNumber(loadNumber + LOAD_NUM_INCREMENT)}
            >
              Load More
            </Button>
          )}
        </div>
      )
    );
  };
  return (
    <>
      <BoldHeaderWithSideItems
        title={
          <>
            <span className="mr-3">Search</span>
            <ExploreCourseTaskSwitch
              switchMode={() => {
                setRedirectFilter(null);
                setMode(EXPLORE_PAGE_MODES.SEARCH_TASK);
              }}
            />
          </>
        }
        left={true}
      >
        <BackToExploreButton setMode={setMode} />
      </BoldHeaderWithSideItems>
      <Form.Group className="mb-5 pb-3">
        <Row>
          <Col className="mb-3" xl={4} md={6} xs={12}>
            <InputGroup size="md" className="rounded">
              <InputGroup.Prepend style={customStyles}>
                <InputGroup.Text className="pr-0 bg-white border-0">
                  <Search className="text-secondary" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className="border-0 text-dark"
                ref={textRef}
                style={customStyles}
                type="text"
                placeholder="Search by name, topic ('cells'), or keyword ('climate')"
                aria-describedby="basic-addon1"
                onChange={onTextChange}
              />
            </InputGroup>
          </Col>
          <Col className="mb-3" xl={3} md={6} xs={12}>
            <Select
              defaultValue={currentFilter.subject.map((subject) =>
                getGenericReactSelectOption(subject)
              )}
              ref={subjectRef}
              placeholder="All Subjects"
              isMulti
              styles={customStyles}
              options={COUNTRY_INFO[REGION_STR].subjects.map((subject) =>
                getGenericReactSelectOption(subject)
              )}
              onChange={(items) => {
                setCurrentFilter({
                  ...currentFilter,
                  subject: items.map((item) => item.value),
                });
              }}
            />
          </Col>
          <Col
            className="mb-3"
            xl={!isSchoolCoursePurchaseModel ? 3 : 2}
            md={6}
            xs={12}
          >
            <Select
              ref={levelsRef}
              placeholder={`All ${YEAR_LEVEL_PREFIX} Levels`}
              isMulti
              styles={customStyles}
              options={COUNTRY_INFO[REGION_STR].levels.map((level) =>
                getGenericReactSelectOption(
                  level,
                  YEAR_LEVEL_PREFIX + " " + level
                )
              )}
              onChange={(items) => {
                setCurrentFilter({
                  ...currentFilter,
                  levels: items.map((item) => item.value),
                });
              }}
            />
          </Col>

          {isSchoolCoursePurchaseModel ? (
            <Col className="mb-3" xl={3} md={6} xs={12}>
              <Select
                ref={purchasedRef}
                placeholder={<>Purchased / Not Purchased</>}
                defaultValue={
                  currentFilter?.onlyShowPurchased
                    ? { value: true, label: "Purchased Courses" }
                    : null
                }
                isClearable
                name="purchaseStatus"
                options={[
                  { value: null, label: "All Courses" },
                  { value: true, label: "Purchased Courses" },
                ].map((item) =>
                  getGenericReactSelectOption(item.value, item.label)
                )}
                onChange={(item) => {
                  setCurrentFilter({
                    ...currentFilter,
                    onlyShowPurchased: !item ? item : item.value,
                  });
                }}
                styles={{
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "0.85rem",
                  }),
                }}
              />
            </Col>
          ) : (
            <Col className="mb-3" xl={2} md={6} xs={12}>
              <Select
                ref={durationRef}
                placeholder="All Durations"
                isClearable
                name="courseType"
                options={Object.values(CourseType).map((item) =>
                  getGenericReactSelectOption(item, CourseTypeDisplayText[item])
                )}
                onChange={(item) => {
                  setCurrentFilter({
                    ...currentFilter,
                    courseType: !item ? item : item.value,
                  });
                }}
                styles={{
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "0.85rem",
                  }),
                }}
              />
            </Col>
          )}
        </Row>
        <Button
          className="text-white float-right"
          variant="link rounded-pill"
          style={{ backgroundColor: "var(--cura-red)" }}
          size="xs"
          onClick={() => {
            textRef.current.value = null;
            subjectRef.current.clearValue();
            levelsRef.current.clearValue();
            durationRef.current?.clearValue();
            purchasedRef.current?.clearValue();
            setCurrentFilter({ ...INIT_FILTER, subject: [] });
          }}
        >
          Clear Filters
        </Button>
      </Form.Group>

      <OverviewModal
        show={showCourseModal}
        isPublic={isPublic}
        setShow={setShowCourseModal}
        calledFrom={EXPLORE_PAGE_MODES.SEARCH}
        isSchoolCoursePurchaseModel={isSchoolCoursePurchaseModel}
        purchasedCourseList={
          isSchoolCoursePurchaseModel ? purchasedCourseList : null
        }
      />
      <CourseCardsFlat
        courseList={{
          courses: filteredCourseList.slice(0, loadNumber),
          projectDict: courseList.projectDict,
        }}
        handleClick={setShowCourseModal}
        isSchoolCoursePurchaseModel={isSchoolCoursePurchaseModel}
        purchasedCourseList={
          isSchoolCoursePurchaseModel ? purchasedCourseList : null
        }
      />

      <LoadMoreButtonSection
        loadNumber={loadNumber}
        total={filteredCourseList.length}
      />
      {filteredCourseList.length === 0 && <NoSearchResultInfo />}
    </>
  );
};

export default SearchPanel;
