import React from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { ArrowLeftShort } from "react-bootstrap-icons";
import { EXPLORE_PAGE_MODES } from "../../utils/constants";

const BackToExploreButton = ({
  setMode,
  mode = EXPLORE_PAGE_MODES.EXPLORE,
}) => {
  return (
    <>
      <Button
        className="d-none d-lg-block"
        size="sm"
        variant="outline-light rounded-pill pl-2"
        onClick={() => setMode(mode)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2 ml-1" />
        Explore
      </Button>
      <Button
        className="d-lg-none text-light mr-2"
        variant="link btn-icon-only"
        size="sm"
        onClick={() => setMode(mode)}
      >
        <ArrowLeftShort size={32} className="mb-1" />
      </Button>
    </>
  );
};

export default BackToExploreButton;
